<template>
    <div class="cost">
        <div
            :class="displayExpressFee ? '' : 'end'"
            class="cost_info van-hairline--bottom">
            <div v-if="displayExpressFee">
                <span class="distribution">配送费用: </span>
                <span class="dis_cos">
                    <span v-if="disCos">￥{{ changeFixed(disCos) }}</span>
                    <span v-else>包邮</span>
                </span>
            </div>
            <div>
                <span class="distribution"> {{ moneyTitle }}:
                </span><span class="sum_money">￥{{ changeFixed(sumMoney) }}
                </span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Cost',
    props: {
        disCos: {
            type: Number,
            default: 0,
        },
        sumMoney: {
            type: Number,
            default: 0,
        },
        displayExpressFee: {
            type: Boolean,
            default: true,
            required: false,
        },
        moneyTitle: {
            type: String,
            default: '商品金额',
            required: false,
        },
    },
    methods: {
        changeFixed(data) {
            return data ? Number(data).toFixed(2) : 0;
        },

    },
};
</script>
<style lang="scss" scoped>
    .cost {
        height: 49px;
        line-height: 49px;
        .cost_info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            &.end {
                justify-content: flex-end;
            }
            .distribution {
                font-size: 14px;
                font-weight: 400;
                color: #62636c;
            }
            .dis_cos {
                font-size: 14px;
                font-weight: 500;
                color: #666;
            }
            .sum_money {
                font-size: 16px;
                font-weight: 500;
                color: #e94947;
            }
        }
    }
</style>
