const math = require('mathjs');
// import math from 'mathjs'

// console.log(0.1 + 0.2); output =>  0.30000000000000004 以下3中写法均计算精度正确 结果均为0.3
// console.log(add(0.1, 0.2));
// console.log(add('0.1', '0.2'));
// console.log(add(0.1, '0.2'))

function add(num1, num2) {
    return math.format((math.add(math.bignumber(num1), math.bignumber(num2))), {
        notation: 'fixed',
    });
}

function subtract(num1, num2) {
    return math.format((math.subtract(math.bignumber(num1), math.bignumber(num2))), {
        notation: 'fixed',
    });
}

function multiply(num1, num2) {
    return math.format((math.multiply(math.bignumber(num1), math.bignumber(num2))), {
        notation: 'fixed',
    });
}

function divide(num1, num2) {
    return math.format((math.divide(math.bignumber(num1), math.bignumber(num2))), {
        notation: 'fixed',
    });
}

export {
    add, subtract, multiply, divide,
};
